body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container_logo_name{
  display: flex;
  flex-wrap: wrap;
}

.speed_dial_whatsapp button{
  position: fixed;
  bottom: 16px;
  right: 16px;
  background-color: #25D366;
  width: 70px;
  height: 70px;
}

.speed_dial_whatsapp button:hover{
  background-color: #25D366;
}

.speed_dial_whatsapp button svg{
  width: 3em;
  height: 3em;
}


.header_title_primary {
  white-space: normal; /* Permite que el texto se divida en varias líneas si es necesario */
  overflow: visible;  /* Asegura que todo el texto se muestre */
  word-wrap: break-word; /* Permite que las palabras largas se rompan */
  text-align: center; /* Alineación centrada */
}

.card_img_gallery_modal{
  max-height: 720px;
}

/* Media query para pantallas pequeñas (menores a 768px) */
@media (max-width: 768px) {
  .header_title_primary {
    font-size: 2.5rem;  /* Reduce el tamaño en pantallas más pequeñas */
    white-space: normal; /* Evita que el texto se corte */
  }

}
@media (max-width: 600px) {
  .card_img_gallery_modal{
    max-height: 480px;
  }
}

/* Media query para pantallas aún más pequeñas (por ejemplo, móviles) */
@media (max-width: 480px) {
  .header_title_primary {
    font-size: 2rem; /* Tamaño pequeño en dispositivos móviles */
  }

  .card_img_gallery_modal{
    max-height: 290px;
  }
}

@media (min-width: 768px) {
  .speed_dial_whatsapp button{
    display: none;
  }
}
/*------------------------------------------*/
/*Inicia Modal Galeria Productos*/

.modal_container_general div{
  border-radius: 5px 5px 0  0;
}

.box-modal{
  box-shadow: none;
}

.div-container-btnModal{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.div_container_modal_description{
  display:flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 5px;
}

.icon_button_close{
  z-index: 1000;
}

.modal_container_title_descripcion{
  padding: 5px;
}

.img_gallery{
  cursor: pointer;
}

.img_gallery:hover{
  opacity: 0.9;
}

.imagen-container {
  position: relative;
  display: inline-block;
  width: 100%; 
  border-radius: 4px;
}

.imagen-container img {
  width: 100%; 
}

.imagen-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9) 100%);
  pointer-events: none;
}
.labels_container_gallery{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal_text_card_image{
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 1000;
}

.modal_label_card_image{
  position: relative;
  margin-left: 15px;
  margin-bottom: 15px;
  margin-top: auto;
  border-radius: 6px;
  padding: 5px 16px 5px 16px;
  cursor: pointer;
}

.modal_label_card_image:hover{
  opacity: 0.9;
}
/*Finaliza Modal Galeria Productos*/
/*------------------------------------------*/
